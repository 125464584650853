import React, {forwardRef, ChangeEvent} from 'react';
import {CheckboxProps, FormControlLabel} from '@mui/material';
import Checkbox from './Checkbox';

interface Props extends CheckboxProps {
  /**
   * Label to be displayed
   */
  label: string;
  /**
   * onChange event
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

/**
 * Checkbox component to show Checkbox with label.
 * More information available here https://mui.com/material-ui/react-checkbox/#label
 */

const CheckboxLabel = forwardRef<HTMLDivElement, Props>(
  ({label, labelPlacement, ...checkboxProps}, ref) => {
    return (
      <FormControlLabel
        labelPlacement={labelPlacement || 'end'}
        control={<Checkbox {...checkboxProps} />}
        label={label}
        ref={ref}
      />
    );
  },
);

export default CheckboxLabel;
