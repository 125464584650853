import dayjs from 'dayjs';
import qs from 'qs';
import 'numeral/locales';
import {saveAs} from 'file-saver';
import DOMPurify from 'dompurify';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import util from 'util';

import {GridSortDirection, GridSortModel} from '@mui/x-data-grid';
import {constants} from '../constants/common';

type CurrencyID =
  | 'USD'
  | 'CAD'
  | 'EUR'
  | 'CRC'
  | 'GBP'
  | 'ILS'
  | 'INR'
  | 'JPY'
  | 'KRW'
  | 'NGN'
  | 'PHP'
  | 'PLN'
  | 'PYG'
  | 'THB'
  | 'UAH'
  | 'VND';

const onLocalHost = window.location.hostname === 'localhost';

const config = require('../config/config.json');

dayjs.extend(utc);
dayjs.extend(timezone);

function minutesToMilliseconds(minutes: number) {
  return minutes * 60000;
}

export const setConfigData = () => {
  const profile = JSON.parse(window.sessionStorage.getItem('profile') || '{}');

  if (!onLocalHost) {
    const windowTemp = {...window} || {};
    window.APP_ENV = windowTemp.APP_ENV || {};
    config.authEndpoint = window.APP_ENV.authEndpoint;
    config.apiEndpoint = window.APP_ENV.apiEndpoint;
    config.emailDomain = window.APP_ENV.emailDomain;
    config.environment = window.APP_ENV.environment?.toLowerCase();
    config.sessionTimeout = minutesToMilliseconds(
      Number(window.APP_ENV.sessionTimeout),
    );
    config.promptBeforeIdleTimeout = minutesToMilliseconds(
      Number(window.APP_ENV.sessionTimeout) -
        Number(window.APP_ENV.promptBeforeIdleTimeout),
    );
    config.webClientRedirectUrl =
      profile && profile.hostName ? profile.hostName : '';
  }
};

setConfigData();

export function isLocalHost() {
  return onLocalHost;
}

export function getConfig() {
  return {...config};
}
export function isEmpty(item: any) {
  if (util.isNullOrUndefined(item) || item === 'undefined') return true;
  let type = '';
  type = typeof item;
  if (type && type === 'object' && Array.isArray(item)) {
    type = 'array';
  }
  switch (type) {
    case 'array':
      return item.length === 0;
    case 'string':
      return item.trim().length === 0;
    case 'object':
      return item && type === 'object' && Object.keys(item).length === 0;
    default:
      return false;
  }
}
const validEmailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isEmailValid(emailAddress: string) {
  return validEmailRegExp.test(emailAddress);
}

export function refreshSideNav() {
  if (window.self !== window.top) {
    window.parent.postMessage(['refreshSideNav'], '*');
  }
}

export function getQueryParameters(url: string) {
  let u = url;
  if (u.startsWith('http')) {
    u = url.split('?').splice(1).join('?');
  }
  return qs.parse(u, {ignoreQueryPrefix: true});
}

// Todo: Test this function
export function decodeURLQueryString(singleParameter: string): string {
  const parameter = singleParameter.replace(/\+/g, '%20'); // Replace '+' with '%20'
  try {
    return decodeURIComponent(parameter);
  } catch (error) {
    console.error('Failed to decode URL query string:', error);
    return ''; // Return an empty string or handle the error as needed
  }
}

export function getCurrencySymbol(currencyId: CurrencyID) {
  if (isEmpty(currencyId)) {
    return '';
  }

  const currencySymbols = {
    USD: '$', // US Dollar
    CAD: '$', // Canadian Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
  };

  return currencySymbols[currencyId] || currencyId;
}

const formatAmount = (amount: number, includeDecimals: boolean = true) => {
  const options = {
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  };
  return new Intl.NumberFormat('en-US', options).format(amount);
};

export function formatCurrency(
  amount: number,
  currency: CurrencyID = 'USD',
  showCurrencySymbol: boolean = false,
  showDecimal: boolean = true,
) {
  if (Number.isNaN(amount)) {
    return amount;
  }
  let currencyString = `${
    showCurrencySymbol ? getCurrencySymbol(currency) : ''
  } ${formatAmount(amount, showDecimal)}`;
  if (currency === 'USD' || currency === 'GBP') {
    currencyString = currencyString.replace(' ', '');
  }
  return currencyString;
}

export function cleanHtml(htmlString: string) {
  return DOMPurify.sanitize(htmlString);
}

export function saveBlobAsDownload(blob: Blob, fileName: string) {
  saveAs(new Blob([blob]), fileName);
}

export function formatPhoneNumber(str: string) {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Remove the matched extension code
    // Change this to format for any country code.
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
}

export function isValidateFile(
  allowedExtensions: string[],
  fileName: string,
): boolean {
  const fileExtension = fileName.split('.').pop()?.toLowerCase(); // Get the extension and convert to lowercase
  return fileExtension ? allowedExtensions.includes(`${fileExtension}`) : false;
}

export const removeWhiteSpacesFromString = (input: string): string => {
  return input && typeof input === 'string' ? input.replace(/\s/g, '') : input;
};

export function isNumeric(str: string | number) {
  return !Number.isNaN(str);
}

export function getUniqueArray(array: any[]) {
  const uniqueElementsSet = new Set(array);
  // Convert the Set back to an array
  return Array.from(uniqueElementsSet);
}

export const decodeHtmlEntities = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  return textarea.value;
};
/**
 * @description: The formatNumber function formats a given number according to the specified locale and formatting options.
 * @param {number} number - The number to format
 * @returns {string} - The formatted number
 */
export function formatNumber(number: number) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
}

/**
 * Converts a given date/time value to the specified user's time zone.
 * If the specified time zone is invalid, it returns the date/time in UTC/GMT format.
 *
 * @param {string|Date} value - The date/time value to be converted.
 * @param {string} sessionUserTimeZone - The user's time zone to which the date/time should be converted.
 * @returns {string} - The converted date/time in the specified time zone or in UTC/GMT if the time zone is invalid.
 */

export function convertToUserTZ(
  value: string | Date,
  sessionUserTimeZone: string,
): string {
  try {
    return dayjs(value).tz(sessionUserTimeZone).format();
  } catch (e) {
    return dayjs(value).utc().format(constants.DATE_TIME_FORMAT);
  }
}
/**
 * Convert Date to DateTime format
 * @param date
 * @returns returns M/D/YYYY h:mm:ss A format
 */
export const formatToDateTime = (date: string) => {
  return dayjs(date).format('M/D/YYYY h:mm:ss A');
};
export type SortJson = {
  ViewSetting: {
    SortSetting: {
      SortIndex: number;
      SortCol: string;
      SortOrder: GridSortDirection;
    }[];
  };
} | null;
export const generateSortJson = (sortQuery: GridSortModel) => {
  if (isEmpty(sortQuery) || sortQuery.length === 0) {
    return null;
  }

  const returnValue = {
    ViewSetting: {
      SortSetting: sortQuery.map((item, index) => {
        return {
          SortIndex: index + 1,
          SortCol: item.field,
          SortOrder: item.sort,
        };
      }),
    },
  };
  return JSON.stringify(returnValue);
};
