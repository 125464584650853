import {isEmpty} from '../../lib/utils';
import {actionTypes} from '../constants/actionTypes';

const initialState = {
  allAccountsSkip: 0,
  allAccountsData: [],
  selectAllAccountsData: [],
  viewSettings: null,
  fetchingAllAccounts: false,
  fetchingAllAccountsFailed: false,
  fetchedAllAccounts: false,
  allAccountsLoading: false,
  allAccountsCount: 0,
  allAccountsNextLink: '',
  allAccountsSelectedRows: [],
  allAccountsUnselectedRows: [],
  allAccountsPageSize: 10,
  selectedAllAccountsPageSize: 10,
  allAccountsBulkUpdateStarted: false,
  allAccountsBulkUpdateCompleted: false,
  allAccountsBulkUpdateFailed: false,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  allAccountsFilterQuery: '',
  allAccountsGlobalFilterQuery: '',
  settingId: null,
  allAccountsTotalsLoading: false,
  allAccountsTotalsData: [],
  fetchingAllAccountsTotals: false,
  fetchingAllAccountsTotalsFailed: false,
  fetchedAllAccountsTotals: false,
  allAccountsErrorMessage: '',
  databaseFileSessionId: '00000000-0000-0000-0000-000000000000',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const allAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ALL_ACCOUNTS:
      return {
        ...state,
        fetchingAllAccounts: true,
        fetchingAllAccountsFailed: false,
        allAccountsLoading: true,
        fetchedAllAccounts: false,
        allAccountsErrorMessage: '',
      };
    case actionTypes.FETCHED_ALL_ACCOUNTS:
      return {
        ...state,
        fetchingAllAccounts: false,
        fetchingAllAccountsFailed: false,
        fetchedAllAccounts: true,
        allAccountsData: action.allAccountsData,
        selectedAllAccountsPageSize: action.selectedAllAccountsPageSize || 10,
        allAccountsLoading: false,
        allAccountsCount: action.allAccountsCount,
        databaseFileSessionId: action.databaseFileSessionId,
        allAccountsNextLink: action.allAccountsNextLink,
        allAccountsSkip: action.allAccountsSkip,
        selectAllAccountsData: action.selectAllAccountsData || [],
        isAllAccountSelected: action.isAllAccountSelected,
        allAccountsErrorMessage: '',
      };
    case actionTypes.FETCHING_ALL_ACCOUNTS_FAILED:
      return {
        ...state,
        fetchingAllAccounts: false,
        fetchingAllAccountsFailed: true,
        fetchedAllAccounts: true,
        allAccountsLoading: false,
        allAccountsData: [],
        allAccountsCount: 0,
        dataBaseFileSessionId: '00000000-0000-0000-0000-000000000000',
        allAccountsErrorMessage: action.allAccountsErrorMessage,
        selectAllAccountsData: !isEmpty(action.allAccountsErrorMessage)
          ? []
          : state.selectAllAccountsData,
      };
    case actionTypes.SET_ALL_ACCOUNTS_SKIP:
      return {
        ...state,
        allAccountsSkip: action.allAccountsSkip,
      };
    case actionTypes.UPDATING_ALL_ACCOUNTS_SELECTED_ROWS:
    case actionTypes.UPDATING_ALL_ACCOUNTS_PAGE_SIZE:
      return {
        ...state,
        allAccountsLoading: true,
      };
    case actionTypes.UPDATED_ALL_ACCOUNTS_SELECTED_ROWS:
      return {
        ...state,
        allAccountsSelectedRows: action.allAccountsSelectedRows,
      };
    case actionTypes.UPDATE_ALL_ACCOUNTS_PAGE_SIZE:
      return {
        ...state,
        allAccountsPageSize: action.allAccountsPageSize,
      };
    case actionTypes.EXPORTING_ALL_ACCOUNTS_TO_EXCEL:
      return {
        ...state,
        allAccountsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_ALL_ACCOUNTS_TO_EXCEL:
      return {
        ...state,
        allAccountsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_ALL_ACCOUNTS_TO_EXCEL_FAILED:
      return {
        ...state,
        allAccountsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_ALL_ACCOUNTS_FILTER_QUERY:
      return {
        ...state,
        allAccountsFilterQuery: action.allAccountsFilterQuery,
      };
    case actionTypes.SET_ALL_ACCOUNTS_GLOBAL_FILTER:
      return {
        ...state,
        allAccountsGlobalFilterQuery: action.allAccountsGlobalFilterQuery,
      };
    case actionTypes.SET_ALL_ACCOUNTS_VIEW_SETTING_KEY:
      return {
        ...state,
        settingId: action.settingId,
      };
    case actionTypes.SET_ALL_ACCOUNTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        viewSettings: {
          ...action.payload.viewSettings,
          columnSetting: action.payload.viewSettings.columnSetting,
          sortSetting: null,
        },
        settingId: action.payload.settingId,
      };
    case actionTypes.FETCHING_ALL_ACCOUNTS_TOTALS:
      return {
        ...state,
        fetchingAllAccountsTotals: true,
        fetchingAllAccountsTotalsFailed: false,
        allAccountsTotalsLoading: true,
      };
    case actionTypes.FETCHED_ALL_ACCOUNTS_TOTALS:
      return {
        ...state,
        fetchingAllAccountsTotals: false,
        fetchingAllAccountsTotalsFailed: false,
        fetchedAllAccountsTotals: true,
        allAccountsTotalsData: action.allAccountsTotalsData,
        allAccountsTotalsLoading: false,
      };
    case actionTypes.FETCHING_ALL_ACCOUNTS_TOTALS_FAILED:
      return {
        ...state,
        fetchingAllAccountsTotals: false,
        fetchingAllAccountsTotalsFailed: true,
        fetchedAllAccountsTotals: true,
        allAccountsTotalsLoading: false,
        allAccountsTotalsData: [],
      };
    case actionTypes.CLEAN_UP_ALL_ACCOUNTS_DATA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default allAccountsReducer;
